import { getAccountingYearMock } from '@dougs/accounting-years/dto';
import { Attachment } from '@dougs/core/files';
import { getUserMock } from '@dougs/user/dto';
import { Company } from '../dto';
import { CompanyLegalForm } from '../enums';

const getDefaults = (): Company => ({
  id: 1,
  ownerId: 1,
  category: '',
  legalForm: CompanyLegalForm.SASU,
  siren: '',
  establishments: [],
  headquarter: {
    siret: '',
    isEstablishmentOwnerTemporary: null,
    establishmentOwnerId: null,
    companyId: 1,
  },
  shareCapital: 1000,
  completedSignupStep: -1,
  isAccountingSignupCompleted: true,
  completedAt: new Date('2021-11-10'),
  accountingFirmId: 1,
  accountingFirmOfficeId: 1,
  diaCode: '',
  isCreated: true,
  completion: {
    percent: 30,
    models: {},
  },
  isLite: false,
  vatConfigurations: [],
  vatDeclarationDay: 1,
  accountingYear: getAccountingYearMock(),
  legalFormConfig: {
    allowKilometricIndemnity: true,
    allowAssociateAccount: true,
    value: 'sas',
    hasGeneralAssembly: true,
    isCompany: true,
    allowMultipleAssociates: false,
  },
  locked: false,
  legalName: '',
  activity: '',
  avatar: {} as Attachment,
  iossInscriptions: [],
  ossInscriptions: [],
  unilateralDecisionDocuments: [],
  retirementContracts: [],
  payrollByDougs: false,
  silaeEmail: '',
  registrationDate: '',
  activityStartsAt: '',
  brandName: '',
  individualEntity: undefined,
  description: '',
  firstAccountingOpeningDate: '',
  invoicingLogo: undefined,
  kbis: [],
  isAccountingRecoveryActivated: false,
  lockDate: undefined,
  providentSocietyContracts: [],
  socialDeclarations: [],
  previousPayrollManagerDSNs: [],
  previousPayrollManagerPayslips: [],
  dsnDocuments: [],
  subscription: undefined,
  lastIssuedInvoiceNumber: 1,
  flags: [],
  taxRegime: null,
  taxRegimes: [],
  isSocialCustomer: false,
  isCreatedByDougs: true,
  salesViaPlatformConfiguration: {
    locked: true,
    salesViaPlatform: true,
    platformCommission: true,
    individualEuropeanSales: true,
    dropShippingOver150: true,
    dropShippingUnder150FromPersonalWeb: true,
    dropShippingUnder150FromMarketPlace: true,
    individualSalesFromUeStock: true,
    individualSalesFromFrenchStock: true,
    individualServicesEuSales: true,
    euIndividualRevenueOver10000: true,
    forceOssWithOption: true,
    shouldShowEuIndividualRevenueOver10000: true,
    shouldShowForceOssWithOption: true,
    canLock: true,
    hasOss: true,
    ossActivationDate: '',
    hasIoss: true,
    iossActivationDate: '',
    dateLocked: true,
  },
  metadata: {
    socialSuspendedAt: new Date('2021-11-10'),
    hasPayrollsInLastTwelveMonths: false,
    social: {
      customCooperativeRate: 1,
      cooperativeRate: 1,
      cooperative: '',
      providentOrganismExecutiveRate: 1,
      providentOrganism: '',
      retirementOrganism: '',
      hasCertifiedOrganism: false,
      isDomiciliated: false,
      providentOrganismRate: 1,
      corporatePurposeActivityType: '',
      corporatePurposeId: -1,
      corporatePurposeModifiedAt: null,
    },
    isDomiciliated: false,
    providentOrganismRate: '',
    hasCertifiedOrganism: false,
    socialCompletedAt: new Date('2021-11-10'),
    automaticPayroll: false,
    certifiedOrganism: undefined,
    courtsAdministrationService: undefined,
    partnershipAccountingFreeMonths: undefined,
    providentOrganismExecutiveRate: '',
    partnershipAccountingHasRemainder: false,
    partnershipAccountingTrialPeriodDays: 1,
    partnershipAlreadyPaidMonthCount: false,
    partnershipDiscountOnCompanyCreation: 1,
    partnershipSubscriptionPaymentMethod: '',
    pipedriveId: 1,
    skipPayrollSurvey: false,
    isTns: false,
    creation: {
      legalForm: CompanyLegalForm.SASU,
      hasMultipleAssociates: false,
      when: null,
    },
    legalRepresentativeEmail: 'test@dougs.fr',
    legalRepresentativeFirstName: 'john',
    legalRepresentativeLastName: 'doe',
  },
  vatConfiguration: {
    type: '',
    activity: '',
  },
  accountingConfiguration: {
    hasMultipleVatRates: true,
    hasDisbursement: true,
    enableBNCAccrualAccounting: true,
    enableAccrualAccounting: true,
    enableInvoicing: true,
    hasMOSS: true,
    hasOss: true,
    ossActivationDate: '',
    hasIoss: true,
    iossActivationDate: '',
    hasEcommerceDispatch: true,
    requiredAccountingSkills: [],
    enableEuSales: true,
  },
  hasEmployee: true,
  vatNumber: '',
  legalForms: [
    {
      isFirst: true,
      startDate: new Date(0),
      value: CompanyLegalForm.SASU,
    },
  ],
  provisionOfPremises: null,
  isRemoteTransmissionEligible: false,
  owner: getUserMock(),
  proofOfCorporateMandates: null,
});

export const getCompanyDataMock = (p?: Partial<Company>): Company => ({
  ...getDefaults(),
  ...p,
});
